<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.10415 5.3295C5.32623 5.11852 5.62744 5 5.94151 5C6.25558 5 6.55679 5.11852 6.77887 5.3295C7.00096 5.54048 7.12572 5.82663 7.12572 6.125C7.12572 6.42337 7.00096 6.70952 6.77887 6.9205C6.55679 7.13147 6.25558 7.25 5.94151 7.25C5.62744 7.25 5.32623 7.13147 5.10415 6.9205C4.88206 6.70952 4.7573 6.42337 4.7573 6.125C4.7573 5.82663 4.88206 5.54048 5.10415 5.3295Z"
      fill="currentColor"
    />
    <path
      d="M9.72537 5.21967C10.0337 4.92678 10.5335 4.92678 10.8419 5.21967C11.1502 5.51256 11.1502 5.98744 10.8419 6.28033L6.10501 10.7803C5.79671 11.0732 5.29684 11.0732 4.98853 10.7803C4.68022 10.4874 4.68022 10.0126 4.98853 9.71967L9.72537 5.21967Z"
      fill="currentColor"
    />
    <path
      d="M9.88888 8.75C9.57481 8.75 9.2736 8.86852 9.05151 9.0795C8.82943 9.29048 8.70467 9.57663 8.70467 9.875C8.70467 10.1734 8.82943 10.4595 9.05151 10.6705C9.2736 10.8815 9.57481 11 9.88888 11C10.2029 11 10.5042 10.8815 10.7262 10.6705C10.9483 10.4595 11.0731 10.1734 11.0731 9.875C11.0731 9.57663 10.9483 9.29048 10.7262 9.0795C10.5042 8.86852 10.2029 8.75 9.88888 8.75Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 0.5C7.60945 0.5 7.22619 0.591468 6.88099 0.764844L1.84905 3.29624C1.4363 3.50399 1.0954 3.82005 0.859912 4.20354C0.625942 4.58456 0.503298 5.01974 0.501938 5.46124C0.460812 7.59085 1.07491 9.6846 2.26522 11.4559C3.45707 13.2296 5.17055 14.5959 7.17624 15.3595L7.19138 15.3652L7.20677 15.3704C7.72132 15.5432 8.27936 15.5432 8.79391 15.3704L8.80934 15.3652L8.82453 15.3594C10.83 14.5957 12.5433 13.2293 13.735 11.4557C14.9251 9.68438 15.5392 7.59075 15.4981 5.46124C15.4967 5.01974 15.3741 4.58456 15.1401 4.20354C14.9046 3.82004 14.5639 3.50407 14.1511 3.29632L9.11947 0.765078C8.77428 0.591701 8.39056 0.5 8 0.5ZM7.61944 2.09069C7.73996 2.03025 7.87006 2 8 2C8.12994 2 8.26005 2.03025 8.38056 2.09069L13.4119 4.62177C13.5563 4.6945 13.6843 4.80961 13.7762 4.95938C13.8684 5.10948 13.9191 5.28599 13.9191 5.46875L13.9191 5.47589L13.9193 5.48302C13.956 7.32469 13.4249 9.12927 12.4041 10.6486C11.387 12.1623 9.93586 13.316 8.25443 13.9605C8.0886 14.0132 7.91205 14.0132 7.74622 13.9605C6.06462 13.3162 4.61327 12.1625 3.59609 10.6488C2.57511 9.12944 2.04399 7.32478 2.08073 5.48303L2.08088 5.47589L2.08087 5.46875C2.08086 5.28599 2.13158 5.10948 2.22376 4.95938C2.31575 4.80956 2.44362 4.6945 2.58811 4.62177L7.61944 2.09069Z"
      fill="currentColor"
    />
  </svg>
</template>
